import React, { useState } from 'react';

// Define LoadingSpinner component before DomainAdvisor
const LoadingSpinner = () => {
    return React.createElement('div',
        { className: "flex items-center justify-center p-4" },
        React.createElement('div',
            {
                style: {
                    width: '24px',
                    height: '24px',
                    border: '3px solid #e0e0e0',
                    borderTop: '3px solid #3b82f6',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite'
                }
            }
        )
    );
};

// Define component without imports
const DomainAdvisor = () => {
    const [description, setDescription] = React.useState('');
    const [domainInput, setDomainInput] = React.useState('');
    const [suggestions, setSuggestions] = React.useState([]);
    const [analysis, setAnalysis] = React.useState(null);
    const [activeTab, setActiveTab] = React.useState('suggest');
    const [loadingReaction, setLoadingReaction] = React.useState(null); // Format: "like-domainname" or "dislike-domainname"
    const [isLoading, setIsLoading] = React.useState(false); // New state for loading suggestions

//    const API_BASE_URL = 'http://domainnamegenerator.ai:8000/api';
    const API_BASE_URL = '/api';


    const generateSuggestions = async (description) => {
    try {
        const response = await fetch(`${API_BASE_URL}/domains/suggest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                description: description
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error generating domain suggestions:', error);

        // Fallback to original local generation if API fails
        const words = description.toLowerCase()
            .split(' ')
            .filter(word => word.length > 3);

        return [
            { domain: `${words[0]}.com`, confidence: 0.9 },
            { domain: `${words[0]}${words[1] || ''}.com`, confidence: 0.8 },
            { domain: `the${words[0]}.com`, confidence: 0.7 },
            { domain: `${words[0]}.io`, confidence: 0.6 },
            { domain: `${words[0]}-${words[1] || 'app'}.com`, confidence: 0.5 }
        ];
    }
};

    // Handler for like button
    const handleLike = async (domain) => {
        try {
            setLoadingReaction(`like-${domain}`);

            // API call to backend
            const response = await fetch(`${API_BASE_URL}/domains/like`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ domain })
            });

            if (!response.ok) {
                throw new Error('Failed to like domain');
            }

            // Update local state
            setSuggestions(suggestions.map(suggestion =>
                suggestion.domain === domain
                    ? { ...suggestion, liked: true, disliked: false }
                    : suggestion
            ));
        } catch (error) {
            console.error('Error liking domain:', error);
            // You might want to show an error message to the user
        } finally {
            setLoadingReaction(null);
        }
    };

    // Handler for dislike button
    const handleDislike = async (domain) => {
        try {
            setLoadingReaction(`dislike-${domain}`);

            // API call to backend
            const response = await fetch(`${API_BASE_URL}/domains/dislike`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ domain })
            });

            if (!response.ok) {
                throw new Error('Failed to dislike domain');
            }

            // Update local state
            setSuggestions(suggestions.map(suggestion =>
                suggestion.domain === domain
                    ? { ...suggestion, liked: false, disliked: true }
                    : suggestion
            ));
        } catch (error) {
            console.error('Error disliking domain:', error);
            // You might want to show an error message to the user
        } finally {
            setLoadingReaction(null);
        }
    };

    // Analyze a specific domain
    const analyzeDomain = async (domain) => {
        try {
            const response = await fetch(`${API_BASE_URL}/domains/analyze`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    domain: domain
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error generating domain analysis:', error);

            return {
                domain,
                pros: [
                    'Easy to remember',
                    'Appropriate length',
                    'Common TLD (.com)',
                    'Brandable name',
                    'No special characters'
                ],
                cons: [
                    'Might be expensive',
                    'Similar domains exist',
                    'Could be confused with similar names',
                    'May be trademarked',
                    'Meaning might not be clear'
                ],
                score: Math.floor(Math.random() * 40 + 60)
            };
        }
    };


//    const handleSuggest = () => {
//        if (description.length < 10) return;
//        setSuggestions(generateSuggestions(description));
//    };

// Updated handleSuggest function to handle async operation
    const handleSuggest = async () => {
        if (description.length < 10) return;

        try {
            setIsLoading(true);
            const newSuggestions = await generateSuggestions(description);
            setSuggestions(newSuggestions);
        } catch (error) {
            console.error('Error in handleSuggest:', error);
            // You might want to show an error message to the user
        } finally {
            setIsLoading(false);
        }
    };

    const handleAnalyze = async () => {
        if (domainInput.length < 4) return;

        try {
            setIsLoading(true);
            const analysis = await analyzeDomain(domainInput);
            setAnalysis(analysis);
        } catch (error) {
            console.error('Error in handleAnalyze:', error);
            // You might want to show an error message to the user
        } finally {
            setIsLoading(false);
        }


//        setAnalysis(analyzeDomain(domainInput));
    };

    const getCloakedLink = (domain) => {
        return `/go/namecheap-domain?domain=${encodeURIComponent(domain)}`;
    };

    return React.createElement('div', { className: "max-w-3xl mx-auto bg-white rounded-lg shadow-lg" },
        React.createElement('div', { className: "flex justify-center space-x-4 p-4 border-b" },
            React.createElement('button', {
                onClick: () => setActiveTab('suggest'),
                className: `px-4 py-2 rounded-lg w-40 ${activeTab === 'suggest' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`
            }, "Get Suggestions"),
            React.createElement('button', {
                onClick: () => setActiveTab('analyze'),
                className: `px-4 py-2 rounded-lg w-40 ${activeTab === 'analyze' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`
            }, "Analyze Domain")
        ),
        React.createElement('div', { className: "p-6" },
            activeTab === 'suggest' ? (
                React.createElement('div', { className: "space-y-4" },
                    React.createElement('textarea', {
                        placeholder: "Describe your website in detail (e.g., purpose, target audience, key features)",
                        value: description,
                        onChange: (e) => setDescription(e.target.value),
                        className: "w-full p-3 border rounded-lg h-32"
                    }),
                    React.createElement('button', {
                        onClick: handleSuggest,
                        disabled: description.length < 10,
                        className: "w-full px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
                    }, "Generate Suggestions"),
                    isLoading ? React.createElement(LoadingSpinner) : (
                    suggestions.length > 0 && React.createElement('div', { className: "space-y-4 mt-6" },
                        React.createElement('h3', { className: "text-xl font-semibold" }, "Suggested Domains"),
                        suggestions.map((suggestion, index) =>
                            React.createElement('div', {
                                key: index,
                                className: "p-4 border rounded-lg shadow"
                            },
                                React.createElement('div', { className: "flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0" },
                                    React.createElement('span', { className: "text-lg font-medium" }, suggestion.domain),
                                    React.createElement('div', { className: "flex flex-wrap items-center gap-4" },
                                        React.createElement('div', {
                                            className: `px-3 py-1 rounded-full text-sm ${
                                                suggestion.confidence > 0.7 ? 'bg-green-100 text-green-800' :
                                                suggestion.confidence > 0.4 ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-red-100 text-red-800'
                                            }`
                                        }, `${Math.round(suggestion.confidence * 100)}% Match`),

                                        React.createElement('div', {
                                            className: `px-3 py-1 rounded-full text-sm ${
                                                suggestion.has_dns ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                                            }`
                                        }, suggestion.has_dns ? 'Not Available' : React.createElement('a', {
                                            href: getCloakedLink(suggestion.domain),
                                            target: '_blank',
                                            rel: 'noopener',
                                            className: 'hover:underline'
                                        }, 'Available - Register Now')
                                        ),

                                        React.createElement('div', { className: "flex space-x-2" },
                                            React.createElement('button', {
                                                onClick: () => handleLike(suggestion.domain),
                                                disabled: loadingReaction === `like-${suggestion.domain}`,
                                                className: `p-2 rounded ${suggestion.liked ? 'bg-green-100 text-green-600' : 'hover:bg-gray-100'}`
                                            }, "👍"),
                                            React.createElement('button', {
                                                onClick: () => handleDislike(suggestion.domain),
                                                disabled: loadingReaction === `dislike-${suggestion.domain}`,
                                                className: `p-2 rounded ${suggestion.disliked ? 'bg-red-100 text-red-600' : 'hover:bg-gray-100'}`
                                            }, "👎")
                                        )
                                    )
                                )
                            )
                        )
                    )
                    )
                )
            ) : (
                React.createElement('div', { className: "space-y-4" },
                    React.createElement('input', {
                        type: "text",
                        placeholder: "Enter a domain name to analyze",
                        value: domainInput,
                        onChange: (e) => setDomainInput(e.target.value),
                        className: "w-full p-3 border rounded-lg"
                    }),
                    React.createElement('button', {
                        onClick: handleAnalyze,
                        disabled: domainInput.length < 4,
                        className: "w-full px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
                    }, "Analyze Domain"),
                    isLoading ? React.createElement(LoadingSpinner) : (
                    analysis && React.createElement('div', { className: "mt-6 p-6 border rounded-lg shadow" },
                        React.createElement('div', { className: "flex justify-between items-center mb-6" },
                            React.createElement('h3', { className: "text-xl font-semibold" }, analysis.domain),
                            React.createElement('div', {
                                className: `px-4 py-2 rounded-full ${
                                    analysis.score >= 80 ? 'bg-green-100 text-green-800' :
                                    analysis.score >= 60 ? 'bg-yellow-100 text-yellow-800' :
                                    'bg-red-100 text-red-800'
                                }`
                            }, `Score: ${analysis.score}/100`)
                        ),
                        React.createElement('div', { className: "grid md:grid-cols-2 gap-6" },
                            React.createElement('div', null,
                                React.createElement('div', { className: "font-medium mb-3" }, "Pros"),
                                React.createElement('ul', { className: "space-y-2" },
                                    analysis.pros.map((pro, index) =>
                                        React.createElement('li', { key: index }, `• ${pro}`)
                                    )
                                )
                            ),
                            React.createElement('div', null,
                                React.createElement('div', { className: "font-medium mb-3" }, "Cons"),
                                React.createElement('ul', { className: "space-y-2" },
                                    analysis.cons.map((con, index) =>
                                        React.createElement('li', { key: index }, `• ${con}`)
                                    )
                                )
                            )
                        )
                    )
                    )
                )
            )
        )
    );
};

// Make it available globally
//window.DomainAdvisor = DomainAdvisor;

export default DomainAdvisor;